import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"

function baseStyles() {
  return (
    <Layout>
      <section>
        <div className="container mx-auto">
          <h1>Site Styles</h1>
        </div>
      </section>

      {/* Heading Styles */}
      <section className="py-4">
        <div className="container mx-auto">
          <h2>Headings</h2>
          <h1>Heading One</h1>
          <h2>Heading Two</h2>
          <h3>Heading Three</h3>
          <h4>Heading Four</h4>
          <h5>Heading Five</h5>
          <h6>Heading Six</h6>
        </div>
      </section>

      {/* Text Styles */}
      <section className="py-4">
        <div className="container mx-auto">
          <h2>Basic Text</h2>
          <h3>Regular Text</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
          <p>
            <Link to="/">Example Link</Link>
          </p>
          <h3>Large Text</h3>
          <p className="largeText">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
          <h3>Small Text</h3>
          <p className="smallText">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
        </div>
      </section>

      {/* List Styles */}
      <section className="py-4">
        <div className="container mx-auto">
          <h2>Lists</h2>
          <ul>
            <li>Unordered List Item 01</li>
            <li>Unordered List Item 02</li>
            <li>Unordered List Item 03</li>
          </ul>
          <ol>
            <li>Ordered List Item 01</li>
            <li>Ordered List Item 02</li>
            <li>Ordered List Item 03</li>
          </ol>
        </div>
      </section>

      {/* Button Styles */}
      <section className="py-4">
        <div className="container mx-auto">
          <h2>Buttons</h2>
          <button className="btn btn-primary">Primary Button</button>
        </div>
      </section>

      {/* Form Field Styles */}
      <section className="py-4">
        <div className="container mx-auto">
          <h2>Form Fields</h2>
          <input type="text" placeholder="Text Field" />
          <textarea placeholder="Text Area Field" />
          <select>
            <option value="selectOption01">Select Option 01</option>
            <option value="selectOption02">Select Option 02</option>
            <option value="selectOption03">Select Option 03</option>
          </select>
          <div className="radioButtons mb-2">
            <label for="radio01">
              <input type="radio" name="radio" value="radio01" id="radio01" />
              Radio Option 01
            </label>
            <label for="radio02">
              <input type="radio" name="radio" value="radio02" id="radio02" />
              Radio Option 02
            </label>
            <label for="radio03">
              <input type="radio" name="radio" value="radio03" id="radio03" />
              Radio Option 03
            </label>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default baseStyles
